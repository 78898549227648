import { useEffect, useRef } from "react";

export const useIdleCallback = (callback: () => void) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const idleCallbackId = requestIdleCallback(() => {
      callbackRef.current();
    });

    return () => {
      cancelIdleCallback(idleCallbackId);
    };
  }, []);
};
