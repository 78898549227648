import React, { memo } from "react";
import { Divider, Space } from "antd";
import { get, truncate } from "lodash";
import { CategoryLabels } from "constants/dashboard.constants";
import AlectifyText from "static/texts.json";
import {
  CALENDAR_STATUS_COMPONENTS,
  EVENT_STATUS_CHIPS,
} from "./CalendarEvent";
import "./Calendar.scss";
import { IEventPopoverContentProps } from "./Calendar.interface";

const EventPopoverContent: React.FC<IEventPopoverContentProps> = memo(
  ({ event }) => {
    const {
      title,
      work_id,
      taskCategory,
      asset,
      asset_package,
      status,
      event_status,
    } = event;

    const assetName =
      get(asset, "[0].name") || get(asset_package, "[0].name") || "None";
    const assetLabel = get(asset, "[0].name")
      ? AlectifyText.SUB_ASSET_NAME
      : get(asset_package, "[0].name")
      ? AlectifyText.PARENT_ASSET_NAME
      : AlectifyText.GENERIC;

    const taskCategoryLabel = taskCategory
      ? CategoryLabels[taskCategory as keyof typeof CategoryLabels] || "Task"
      : "Task";

    return (
      <div className="popover-container-calendar">
        <Space direction="vertical">
          <div className="heading-sub-container">
            <span className="heading">{truncate(title) || "(No title)"}</span>
            <Divider className="mb-5 mt-5" />
            <div className="sub-heading">
              Work Id: <strong>{work_id}</strong>
            </div>
            <div className="sub-heading">
              Type: <strong>{taskCategoryLabel}</strong>
            </div>
            <div className="sub-heading">
              Asset: <strong>{assetName}</strong> (<strong>{assetLabel}</strong>
              )
            </div>
          </div>

          <Divider className="mb-5 mt-5" />

          <div className="chips-main">
            {
              CALENDAR_STATUS_COMPONENTS[
                status as keyof typeof CALENDAR_STATUS_COMPONENTS
              ]
            }
            {
              EVENT_STATUS_CHIPS(event)[
                event_status as keyof typeof EVENT_STATUS_CHIPS
              ]
            }
          </div>
        </Space>
      </div>
    );
  },
);

export default EventPopoverContent;
