import { get } from "lodash";
import { memo } from "react";
import { Popover } from "antd";
import { ICalendarEvent } from "services/calendar/calendar.interface";
import {
  CalendarEventStatusEnum,
  CalendarEventTypesEnum,
  PM_STATUS,
} from "enums";
import { CheckMarkSingleIcon } from "components/icons";
import TasksIcon from "components/icons/TasksIcon";
import PmExternalIcon from "components/icons/PmExternalIcon";
import PmInternalIcon from "components/icons/PmInternalIcon";
import { ROUTES } from "routes/Routes.constants";
import { useNavigate } from "react-router-dom";
import {
  ScheduledTag,
  SkippedTag,
  WaitingForReviewTag,
} from "components/shared/tags";
import DelayedTag from "components/shared/tags/DelayedTag";
import ClosedTag from "components/shared/tags/ClosedTag";
import InProgressTag from "components/shared/tags/InProgressTag";
import EventPopoverContent from "./EventPopoverContent";
import "./Calendar.scss";

export const EVENT_ICONS = {
  [CalendarEventTypesEnum.TASKS]: <TasksIcon />,
  [CalendarEventTypesEnum.PM_EXTERNAL]: <PmExternalIcon />,
  [CalendarEventTypesEnum.PM_INTERNAL]: <PmInternalIcon />,
};

export const CALENDAR_STATUS_COMPONENTS = {
  [PM_STATUS.PENDING]: <ScheduledTag />,
  [PM_STATUS.WAITING_FOR_REVIEW]: <WaitingForReviewTag />,
  [PM_STATUS.INPROGRESS]: <InProgressTag />,
};

export const EVENT_STATUS_CHIPS = (props: ICalendarEvent) => ({
  [CalendarEventStatusEnum.DELAYED]: <DelayedTag />,
  [CalendarEventStatusEnum.ON_TIME_COMPLETED]: (
    // <ClosedTag text={`Closed on ${displayDateTime(props?.completedAt)}`} />
    <ClosedTag text={`Closed`} />
  ),
  [CalendarEventStatusEnum.DELAYED_COMPLETED]: (
    <ClosedTag
      text="Closed"
      // text={`Closed on ${displayDateTime(props?.completedAt)}`}
      color={"#d92323"}
      fill={"#f94c4c"}
    />
  ),
  [CalendarEventStatusEnum.SKIPPED]: <SkippedTag />,
});
export const EVENT_CLASSES: any = {
  [CalendarEventStatusEnum.DELAYED]: "delayed",
  [CalendarEventStatusEnum.PENDING]: "pending",
  [CalendarEventStatusEnum.ON_TIME_COMPLETED]: "completed",
  [CalendarEventStatusEnum.DELAYED_COMPLETED]: "completed",
  [CalendarEventStatusEnum.SKIPPED]: "skipped",
};

export const EVENT_Text: any = {
  [CalendarEventStatusEnum.DELAYED]: "Over Due",
  [CalendarEventStatusEnum.DELAYED_COMPLETED]: "Completed(Due Date Passed)",
};

const CalendarEvent: React.FC<ICalendarEvent> = (props: ICalendarEvent) => {
  const masterProject = get(props, "master_project[0]");
  const navigate = useNavigate();

  const goToPmDetailPage = (props: any) => {
    const masterProjectId = get(props, "master_project[0].id");
    const subProjectId = get(props, "sub_project[0].id");
    const pmId = props.id;
    const pmType = props.type;

    const url = `${ROUTES.MY_ITEMS}/pm/${masterProjectId}/${subProjectId}/${pmId}?pmType=${pmType}`;
    navigate(url);
  };

  return (
    <div className="event-container">
      <Popover
        content={<EventPopoverContent event={props} />}
        trigger="hover"
        placement="left"
        overlayClassName={"events-popover"}
      >
        <div
          className={`alectify-calendar-event ${
            EVENT_CLASSES[props.event_status as CalendarEventStatusEnum]
          }`}
          style={{ backgroundColor: masterProject?.color || "transparent" }}
          onClick={() => {
            goToPmDetailPage(props);
          }}
        >
          <div
            className={`event-content ${
              props?.event_status === CalendarEventStatusEnum.DELAYED ||
              props?.event_status ===
                CalendarEventStatusEnum.DELAYED_COMPLETED ||
              props?.event_status === CalendarEventStatusEnum.SKIPPED
                ? "event-left-red-borders"
                : "event-left-normal-borders"
            }`}
          >
            {props?.event_status ===
              CalendarEventStatusEnum.DELAYED_COMPLETED ||
            props?.event_status ===
              CalendarEventStatusEnum.ON_TIME_COMPLETED ? (
              <div className="calendar-check-mark">
                <CheckMarkSingleIcon fill={"#fff"} />
              </div>
            ) : (
              <></>
            )}
            <div className="event-title">
              <span>{props?.title || "(No title)"}</span>
            </div>

            <div className="event-name text-ellipsis">
              <span>
                {get(props, "asset[0].name") ||
                  get(props, "asset_package[0].name") ||
                  "-"}
              </span>
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default memo(CalendarEvent);
