//libs
import { ReactNode, useEffect, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Breadcrumb,
  Col,
  message,
  Row,
  Space,
  // Skeleton,
  // Space,
  Tabs,
  TabsProps,
} from "antd";
import { get, isEmpty } from "lodash";

//interfaces
import { IWorkOrderPMDetails } from "pages/my-work-orders/details/pm/WorkOrderPMDetails.interface";

//services
import {
  deletePMExternal,
  fetchExternalPMDetails,
  updatePrevMaintenanceStatus,
} from "services/pm-external/pm-external.service";
import { undoTaskStatus } from "services/tasks/tasks.service";

//enums
import { CommentReferenceEnum, PM_STATUS } from "enums";
import { IPmExternal, PM_TYPES, actions } from "redux/components/pm-external";

//components
import PmExternalTaskCompleteForm from "components/shared/pm-external-table/PmExternalTaskCompleteForm";
import PreventiveDocumentsAttachment from "components/shared/preventive-documents/PreventiveDocumentsAttachment";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import PMCreateEditForm from "components/shared/pm-create-edit-form";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { IPagination } from "components/shared/table/AlectifyTable.interface";
import WorkOrderPMEDetailsActionItems from "./WorkOrderPMEDetails.actions";
// import { ACTION_REQUIRED_COMPONENTS } from "components/shared/pm-external-table/effects/usePmExternalColumns";
// import { getPMEActionRequired } from "components/shared/pm-external-table/PmExternal.helpers";
import { PM_EDITING_TYPES } from "components/shared/pm-create-edit-form/PMCreateEditForm.constants";
import ProcedureDetail from "components/shared/procedure-detail";
import { WarningIcon } from "components/icons";
import DocumentsIcon from "components/icons/DocumentsIcon";
// import ProcedureIcon from "components/icons/ProceduresIcon";
// import AlectifyEmptyState from "components/shared/empty/AlectifyEmptyState";
import { taskStatusStepIcon } from "components/shared/tasks-table/effects/useTasksColumns";

//utils & helpers
import AlectifyText from "static/texts.json";
import {
  // enumToTile,
  // capitalizeFirstLetter,
  // displayDateTime,
  // enumToTile,
  isExternalUser,
  // renderAmount,
  updateComponentByNotfication,
} from "utils/helpers";

//redux
import { IRootState } from "redux/rootReducer";
import { setUploadDocuments } from "redux/components/common/sources";
import { useDispatch, useSelector } from "react-redux";
import { ITask } from "redux/components/tasks";

//constants
import { ROUTES } from "routes/Routes.constants";
import { MODAL_NAMES } from "constants/modal.constants";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { MESSAGES } from "constants/messages";
import { PM_WARNING_MESSAGES } from "constants/warning-messages.constant";
// import { CategoryLabels } from "constants/dashboard.constants";

//styles
import "./WorkOrderPMDetails.scss";
import ReviewConfirmationModal from "components/shared/confirmation/ReviewConfirm";
import WorkOrderSpareParts from "components/shared/work-order-spare-parts";
// import InventoryIcon from "components/icons/InventoryIcon";
import DetailIcon from "components/icons/DetailIcon";
// import WorkOrderBasicInfo from "./WorkOrderBasicInfo";
// import { countCheckedSteps } from "pages/procedures/Procedures.helpers";
import { IAlectifyModalProps } from "components/shared/modal/AlectifyModal.interface";
import AlectifyButton from "components/shared/button";
import DraggableButton from "components/shared/draggable-button/DraggableButton";
import Comments from "components/shared/comments";
import WorkOrderStatus from "./WorkOrderStatus";
import BackArrowIcon from "components/icons/BackArrowIcon";
import AttachmentIcon from "components/icons/AttachmentIcon";

const WorkOrderPMDetails: React.FC<IWorkOrderPMDetails> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [FormInstance] = useForm();
  const [submitReviewForm] = useForm();

  const pmType = new URLSearchParams(location.search).get("pmType");
  const { pmId, subProjectId } = useParams();
  const { user } = useSelector((state: IRootState) => state.auth);

  const [loader, setLoader] = useState<boolean>(false);
  const [pmExternal, setPmExternal] = useState<ITask | null>(null);
  const [activeTab, setActiveTab] = useState<string>("details");
  const [showComments, setShowComments] = useState<boolean>(true);

  const getPmExternalDetails = async (id: string) => {
    setLoader(true);
    try {
      const response = await fetchExternalPMDetails(id);
      if (response.status) {
        setPmExternal(response.data);
      }
      setLoader(false);
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };

  const isStatusPendingOrWaitingForReview = () => {
    const pm = pmExternal;
    return (
      pm &&
      [
        PM_STATUS.PENDING,
        PM_STATUS.WAITING_FOR_REVIEW,
        PM_STATUS.INPROGRESS,
      ].includes(pm.status)
    );
  };

  const prepareRoutes = (type: string): string => {
    let url: string = "";
    if (type === "home")
      url = (!isExternalUser(user) && navigate(`${ROUTES.PROJECT}`)) || "";
    if (type === "projects")
      url =
        (!isExternalUser(user) &&
          `${ROUTES.PROJECT}/${
            !isEmpty(pmExternal) && get(pmExternal.project, "id")
          }`) ||
        "";
    if (type === "subproject")
      url =
        (!isExternalUser(user) &&
          `${ROUTES.PROJECT}/${
            !isEmpty(pmExternal) && get(pmExternal.project, "id")
          }${ROUTES.SUB_PROJECT}/${
            !isEmpty(pmExternal) && get(pmExternal.subProject, "id")
          }?page=${
            pmType === PM_TYPES.PM_EXTERNAL
              ? "assets"
              : pmType === PM_TYPES.TASK
              ? "tasks"
              : "pm-internal"
          }`) ||
        "";
    return url;
  };

  const updatePMEStatus = async (
    pmExternal: ITask | IPmExternal,
    status: string,
    date?: Date,
    estimatedHours?: string,
    estimatedCost?: string,
    commentId?: string,
  ): Promise<boolean> => {
    const message =
      PM_WARNING_MESSAGES[status] || MESSAGES.PM_EXTERNAL.COMMON_WARNING;
    return new Promise((resolve, reject) => {
      openWarningModal(
        message,
        async () => {
          setLoader(true);
          try {
            await updatePrevMaintenanceStatus(pmExternal.id, status, {
              date: date ? date : new Date(),
              estimatedHours,
              estimatedCost,
              commentId: commentId || undefined,
            });
            ModalServiceInstance.close(AlectifyModal, {
              name: MODAL_NAMES.PM_SUBMIT_FOR_REVIEW_MODAL,
            });
            submitReviewForm.resetFields();
            dispatch(setUploadDocuments([]));
            getPmExternalDetails(pmExternal?.id);
            resolve(true);
          } catch (ex) {
            setLoader(false);
            console.log(ex);
            reject(ex);
          }
        },
        () => resolve(false),
        "",
        status,
        pmExternal,
      );
    });
  };

  const openPMECreationDrawer = (
    _: any,
    isEditing: boolean = false,
    editType?: string,
    pmExternal?: IPmExternal,
    isCopying?: boolean,
  ) => {
    const config = {
      width: 1436,
      title: `Update ${(!isEmpty(pmExternal) && pmExternal.workTitle) || "-"}`,
      showFooter: true,
      name: DRAWER_CONSTANTS.CREATE_PM_DRAWER,
      onClose: () => {
        FormInstance.resetFields();
        DrawerServiceInstance.close(AlectifyDrawer, {
          name: DRAWER_CONSTANTS.CREATE_PM_DRAWER,
        });
        getPmExternalDetails(pmId || "");
      },
      onSubmit: FormInstance.submit,
      closeIcon: null,
      closable: false,
      destroyOnClose: true,
      children: (
        <PMCreateEditForm
          subProjectId={pmExternal?.subProject.id}
          masterProjectId={pmExternal?.project.id}
          FormInstance={FormInstance}
          editing={isEditing}
          copying={isCopying}
          editType={editType || ""}
          pmId={pmExternal?.id || ""}
          onEdit={openPMECreationDrawer as any}
          procedure={
            (!isEmpty(pmExternal) &&
              (editType === PM_EDITING_TYPES.ALL_EVENTS
                ? pmExternal?.masterPreventiveMaintenance.procedure
                : pmExternal?.procedure)) ||
            undefined
          }
          procedureLibraryId={
            (!isEmpty(pmExternal) &&
              (editType === PM_EDITING_TYPES.ALL_EVENTS
                ? pmExternal?.masterPreventiveMaintenance.procedureLibraryId
                : pmExternal?.procedureLibraryId)) ||
            undefined
          }
          callbackEffects={() => getPmExternalDetails(pmId || "")}
          isTask={pmType === PM_TYPES.TASK}
          isPmExternal
          isMyWorkOrder
          myWorkOrder
        />
      ),
    };
    DrawerServiceInstance.open(AlectifyDrawer, {
      width: 1436,
      title: `Update ${(!isEmpty(pmExternal) && pmExternal.workTitle) || "-"}`,
      showFooter: true,
      name: DRAWER_CONSTANTS.CREATE_PM_DRAWER,
      onClose: () => {
        FormInstance.resetFields();
        DrawerServiceInstance.close(AlectifyDrawer, {
          name: DRAWER_CONSTANTS.CREATE_PM_DRAWER,
        });
        getPmExternalDetails(pmId || "");
      },
      onSubmit: FormInstance.submit,
      closeIcon: null,
      closable: false,
      destroyOnClose: true,
      children: (
        <PMCreateEditForm
          config={config}
          subProjectId={pmExternal?.subProject.id}
          masterProjectId={pmExternal?.project.id}
          FormInstance={FormInstance}
          editing={isEditing}
          copying={isCopying}
          editType={editType || ""}
          pmId={pmExternal?.id || ""}
          onEdit={openPMECreationDrawer as any}
          procedure={
            (!isEmpty(pmExternal) &&
              (editType === PM_EDITING_TYPES.ALL_EVENTS
                ? pmExternal?.masterPreventiveMaintenance.procedure
                : pmExternal?.procedure)) ||
            undefined
          }
          procedureLibraryId={
            (!isEmpty(pmExternal) &&
              (editType === PM_EDITING_TYPES.ALL_EVENTS
                ? pmExternal?.masterPreventiveMaintenance.procedureLibraryId
                : pmExternal?.procedureLibraryId)) ||
            undefined
          }
          callbackEffects={() => getPmExternalDetails(pmId || "")}
          isTask={pmType === PM_TYPES.TASK}
          isPmExternal
          isMyWorkOrder
          myWorkOrder
        />
      ),
    });
  };

  const openPMESubmitForReviewModal = (pm: IPmExternal) => {
    const config: IAlectifyModalProps = {
      name: MODAL_NAMES.PM_SUBMIT_FOR_REVIEW_MODAL,
      title: "Submit for Review",
      onCancel: () => {
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.PM_SUBMIT_FOR_REVIEW_MODAL,
        });
        submitReviewForm.resetFields();
        dispatch(setUploadDocuments([]));
      },
      onOk: submitReviewForm.submit,
      okText: "Submit",
      closeIcon: false,
      style: { top: "40px" },
      children: (
        <PmExternalTaskCompleteForm
          form={submitReviewForm}
          subProjectId={pmExternal?.subProject.id || ""}
          pmExternal={pm}
          masterProjectId={pmExternal?.project.id || ""}
          fetchPmExternal={(options?: IPagination) =>
            getPmExternalDetails(pmId || "")
          }
          updateStatus={updatePMEStatus}
          isDetailPage
          procedureSteps={pmExternal?.procedure?.procedureSteps}
        />
      ),
    };
    ModalServiceInstance.open(AlectifyModal, config);
  };

  const openPMEAttachmentsDrawer = () => {
    DrawerServiceInstance.open(AlectifyDrawer, {
      width: 860,
      title: pmExternal?.asset?.name || pmExternal?.area?.name || "",
      onClose: () => DrawerServiceInstance.close(AlectifyDrawer),
      children: <PreventiveDocumentsAttachment details={pmExternal} />,
    });
  };

  const openProcedureCheckListDrawer = () => {
    DrawerServiceInstance.open(AlectifyDrawer, {
      width: 680,
      title: "Procedure",
      name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
      onClose: () => {
        DrawerServiceInstance.close(AlectifyDrawer, {
          name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
        });
      },
      showFooter: true,
      readOnly: true,
      cancelText: AlectifyText.CLOSE,
      destroyOnClose: true,
      children: (
        <ProcedureDetail
          procedure={pmExternal?.procedure as any}
          taskId={pmExternal?.id}
          isDisabled={
            pmExternal?.status === PM_STATUS.COMPLETED ||
            pmExternal?.status === PM_STATUS.SKIPPED
          }
        />
      ),
    });
  };

  const removePMExternal = async (pmId: string) => {
    dispatch(actions.setPMExterLoader(true));
    try {
      await deletePMExternal(pmId);
      message.success("Deleted");
      navigate(-1);
      dispatch(actions.setPMExterLoader(false));
    } catch (ex) {
      dispatch(actions.setPMExterLoader(false));
      console.log(ex);
    }
  };

  const openWarningModal = (
    message: string,
    successCb: () => void,
    cancelCb?: () => void,
    note?: string,
    status?: string,
    pmExternal?: ITask | IPmExternal,
  ) => {
    if (status === "COMPLETED") {
      const onCancel = () =>
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.REVIEW_COMPLETED,
        });
      ModalServiceInstance.open(AlectifyModal, {
        name: MODAL_NAMES.REVIEW_COMPLETED,
        title: AlectifyText.REVIEW_COMPLETE,
        // footer: false,
        okText: AlectifyText.REVIEW_COMPLETE,
        onOk: () => {
          successCb();
          onCancel();
        },
        onCancel,
        children: (
          <ReviewConfirmationModal
            pmExternal={pmExternal}
            icon={WarningIcon}
            message={message}
            onConfirm={() => {
              successCb && successCb();
              onCancel();
            }}
            onCancel={() => {
              cancelCb && cancelCb();
              onCancel();
            }}
          />
        ),
      });
    } else {
      successCb && successCb();
      // const onCancel = () =>
      //   ModalServiceInstance.close(AlectifyModal, {
      //     name: MODAL_NAMES.PM_EXTERNAL_REMOVE_WARNING_MODAL,
      //   });
      // ModalServiceInstance.open(AlectifyModal, {
      //   name: MODAL_NAMES.PM_EXTERNAL_REMOVE_WARNING_MODAL,
      //   title: AlectifyText.CONFIRMATION,
      //   footer: false,
      //   onCancel,
      //   children: (
      //     <ConfirmationModal
      //       icon={WarningIcon}
      //       message={message}
      //       note={note && note}
      //       onConfirm={() => {
      //         successCb && successCb();
      //         onCancel();
      //       }}
      //       onCancel={() => {
      //         cancelCb && cancelCb();
      //         onCancel();
      //       }}
      //     />
      //   ),
      // });
    }
  };
  const openWarningModalCallback = () => {
    openWarningModal(
      `You are about to UNDO the State from ${pmExternal?.status} to ${
        pmExternal?.completedAt
          ? AlectifyText.WAITING_FOR_REVIEW
          : AlectifyText.PENDING
      }`,
      handleUndoConfirm,
      () => {},
      "Are you sure?",
    );
  };
  const openRemoveWaringModal = (pmId: string) => {
    openWarningModal(MESSAGES.PM_EXTERNAL.REMOVER_WARNING, () =>
      removePMExternal(pmId),
    );
  };

  const submittedInformationModal = () => {
    const onCancel = () =>
      ModalServiceInstance.close(AlectifyModal, {
        name: MODAL_NAMES.REVIEW_COMPLETED,
      });
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.REVIEW_COMPLETED,
      title: AlectifyText.REVIEW_COMPLETE,
      onCancel,
      footer: (
        <AlectifyButton
          type="default"
          className="alectify-default-button"
          text="Close"
          onClick={onCancel}
        />
      ),
      children: (
        <ReviewConfirmationModal
          pmExternal={pmExternal as any}
          icon={WarningIcon}
          message={"message"}
          onConfirm={() => {
            onCancel();
          }}
          onCancel={() => {
            onCancel();
          }}
        />
      ),
    });
  };

  useEffect(() => {
    if (pmId) {
      getPmExternalDetails(pmId);
    }
  }, [pmId]);

  const openSpareParts = (record: ITask | IPmExternal) => {
    DrawerServiceInstance.open(AlectifyDrawer, {
      width: 1000,
      name: MODAL_NAMES.WORK_ODERS_SPARE_PARTS_MODAL,
      title: AlectifyText.SPARE_PARTS_USED,
      onClose: () => {
        DrawerServiceInstance.close(AlectifyDrawer, {
          name: MODAL_NAMES.WORK_ODERS_SPARE_PARTS_MODAL,
        });
      },
      closeIcon: true,
      footer: null,
      children: (
        <WorkOrderSpareParts
          record={record}
          callback={() => getPmExternalDetails(pmExternal?.id || "")}
          showDrawSparePartsButton={
            record.status !== PM_STATUS.COMPLETED &&
            record.status !== PM_STATUS.SKIPPED
          }
        />
      ),
    });
  };

  const getActionButton = (): ReactNode => {
    return (
      <div className="alectify-detail-page-action-buttons-container">
        <WorkOrderPMEDetailsActionItems
          currentUser={user}
          pmExternal={pmExternal}
          projectId={pmExternal?.project?.id || ""}
          subProjectId={pmExternal?.subProject?.id || ""}
          type={PM_TYPES.PM_EXTERNAL}
          updateStatus={updatePMEStatus}
          onEdit={openPMECreationDrawer as any}
          openAttachmentsDrawer={openPMEAttachmentsDrawer}
          openSubmitForReviewModal={openPMESubmitForReviewModal}
          openProcedureCheckListDrawer={openProcedureCheckListDrawer}
          removePMExternal={openRemoveWaringModal}
          openWarningModal={openWarningModalCallback}
          taskStatusStepIcon={taskStatusStepIcon}
          openSpareParts={openSpareParts as any}
          pmDetailPageActive
        />
      </div>
    );
  };

  const items: TabsProps["items"] = [
    {
      key: "details",
      label: (
        <>
          <DetailIcon /> {AlectifyText.STATUS}
        </>
      ),
      children: (
        // <WorkOrderBasicInfo
        //   task={pmExternal}
        //   actionButton={getActionButton()}
        //   loader={loader}
        //   prepareRoutes={prepareRoutes}
        //   setActiveTab={setActiveTab}
        //   setLoader={setLoader}
        //   submittedInformationModal={submittedInformationModal}
        //   getPmExternalDetails={getPmExternalDetails}
        // />

        <WorkOrderStatus
          task={pmExternal}
          actionButton={getActionButton()}
          loader={loader}
          prepareRoutes={prepareRoutes}
          submittedInformationModal={submittedInformationModal}
          getPmExternalDetails={getPmExternalDetails}
          procedure={pmExternal?.procedure as any}
          taskId={pmExternal?.id}
          subProjectId={subProjectId}
          referenceId={pmId || ""}
          isDisabled={!isStatusPendingOrWaitingForReview()}
        />
      ),
    },
    {
      key: "documents",
      label: (
        <>
          <AttachmentIcon /> {AlectifyText.ATTACHMENTS} ({pmExternal?.documents}
          )
        </>
      ),
      children: (
        <PreventiveDocumentsAttachment
          details={pmExternal}
          isDetailPage
          callback={() =>
            pmExternal?.id && getPmExternalDetails(pmExternal?.id)
          }
        />
      ),
    },
    // {
    //   key: "spare-parts",
    //   label: (
    //     <>
    //       <InventoryIcon /> {AlectifyText.SPARE_PARTS_USED} (
    //       {pmExternal?.sparePartsUsed?.count || "0"})
    //     </>
    //   ),
    //   children: (
    //     <WorkOrderSpareParts
    //       record={pmExternal as any}
    //       showDrawSparePartsButton={
    //         pmExternal?.status !== PM_STATUS.COMPLETED &&
    //         pmExternal?.status !== PM_STATUS.SKIPPED
    //       }
    //       callback={() => pmExternal?.id && getPmExternalDetails(pmExternal.id)}
    //     />
    //   ),
    // },
    // {
    //   key: "procedureSteps",
    //   label: (
    //     <>
    //       <ProcedureIcon /> {AlectifyText.PROCEDURE_STEPS} (
    //       {countCheckedSteps(pmExternal?.procedure?.procedureSteps || [])})
    //     </>
    //   ),
    //   children: isEmpty(pmExternal?.procedure) ? (
    //     <AlectifyEmptyState />
    //   ) : (
    //     <ProcedureDetail
    //       procedure={pmExternal?.procedure as any}
    //       taskId={pmExternal?.id}
    //       subProjectId={subProjectId}
    //       referenceId={pmId || ""}
    //       isDisabled={!isStatusPendingOrWaitingForReview()}
    //       getPmExternalDetails={getPmExternalDetails}
    //     />
    //   ),
    // },
  ];

  const handleUndoConfirm = async () => {
    try {
      ModalServiceInstance.close(AlectifyModal, {
        name: MODAL_NAMES.UNDO_WARNING_MODAL,
      });

      const id = pmExternal?.id || "";
      const resp = await undoTaskStatus(id);
      if (resp?.status) {
        message.success(
          `${pmExternal?.workTitle} ${AlectifyText.TASK_STATUS_CHANGE_CONTEXT} ${pmExternal?.status} to ${resp.data?.status}`,
        );
        getPmExternalDetails(pmId || "");
      }
    } catch (error) {
      message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
    }
  };

  updateComponentByNotfication(
    pmType as string,
    (payload) => {
      if (!isEmpty(payload)) {
        if (payload.data?.userId !== user.id) {
          window.location.reload();
        }
      }
    },
    true,
  );

  return (
    <Row className="alectify-work-order-details-container">
      <Col
        span={showComments ? 18 : 24}
        className="sub-project-tabs alectify-work-order-task-documents-details-section "
      >
        {!isEmpty(pmExternal) && (
          <div className="mb-10 d-flex align-items-center">
            <div
              className="cursor-pointer mt-2 mr-5"
              onClick={() => navigate(-1)}
            >
              <BackArrowIcon height="12" width="20" fill="#3e403f" />
            </div>

            <Breadcrumb
              items={[
                {
                  title: "Home",
                  href: `#`,
                  onClick: () => navigate(prepareRoutes("home")),
                },
                {
                  title: `${pmExternal?.project.name || "-"}`,
                  href: "#",
                  onClick: () => navigate(prepareRoutes("projects")),
                },
                {
                  title: `${pmExternal?.subProject.name || "-"}`,
                  href: "#",
                  onClick: () => navigate(prepareRoutes("subproject")),
                },
                {
                  title: `${!isEmpty(pmExternal) ? pmExternal.workTitle : "-"}`,
                },
              ]}
            />
          </div>
        )}
        {/* {!isEmpty(pmExternal) && (
          <Col span={24} className="pm-detail-title">
            <Space>
              <div className="cursor-pointer" onClick={() => navigate(-1)}>
                <BackArrowIcon />
              </div>
              <Space>
                <span className="pm-detail-title-text">
                  {pmExternal?.workTitle}
                </span>
              </Space>
            </Space>
          </Col>
        )} */}
        <Tabs
          items={items}
          defaultActiveKey="details"
          activeKey={activeTab}
          onChange={setActiveTab}
        />
      </Col>
      <>
        {showComments ? (
          <Col
            span={6}
            className="alectify-work-order-task-comments-details-section"
          >
            <Comments
              details={pmExternal}
              referenceId={pmId || ""}
              reference_type={
                CommentReferenceEnum[pmType as CommentReferenceEnum]
              }
              subProjectId={subProjectId || ""}
              onChangeHideButton={(show: boolean) => setShowComments(!show)}
              showHideButton
              showTitleAndCount
              isDetailPage
            />
          </Col>
        ) : (
          <DraggableButton
            details={pmExternal}
            showComments={showComments}
            onShowComments={setShowComments}
            referenceId={pmId || ""}
            reference_type={
              CommentReferenceEnum[pmType as CommentReferenceEnum]
            }
            subProjectId={subProjectId || ""}
            showTitleAndCount
            isDetailPage
            count={(pmExternal?.comments as any) || 0}
            callback={() => getPmExternalDetails(pmId || "")}
          />
        )}
      </>
    </Row>
  );
};

export default WorkOrderPMDetails;
