import { Breadcrumb, Col, Row, Tabs, TabsProps, Typography } from "antd";
import PmExternalIcon from "components/icons/PmExternalIcon";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { IRootState } from "redux/rootReducer";
import { ROUTES } from "routes/Routes.constants";
import AlectifyText from "static/texts.json";
import PmExternal from "./PmExternal";
import Tasks from "./Tasks";
import { isExternalUser, sumValues } from "utils/helpers";
import TasksIcon from "components/icons/TasksIcon";
import "./WorkOrder.styles.scss";
import ProcedureViewSwitcher from "pages/procedures/procedures-listing/ProcedureViewSwitcher";
import { setWorkOrderCardSwitcher } from "redux/components/pm-external/sources";

const MyWorkOrders: React.FC = () => {
  const {
    common,
    auth: { user },
    tasks,
    pmExternal,
  } = useSelector((state: IRootState) => state);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const activeTab =
    new URLSearchParams(location.search).get("page") || "pm-external";

  const handleSwitchView = (value: boolean) => {
    dispatch(setWorkOrderCardSwitcher(value));
  };

  const items: TabsProps["items"] = [
    {
      key: "pm-external",
      label: (
        <>
          <PmExternalIcon />{" "}
          {`${AlectifyText.PM_EXTERNAL} (${sumValues([
            pmExternal.pendingCounts,
            pmExternal.completedCounts,
            pmExternal.skippedCounts,
          ])})`}
        </>
      ),
      children: (
        <PmExternal scroll={{ x: "max-content", y: "calc(100vh - 350px)" }} />
      ),
    },
    {
      key: "tasks",
      label: (
        <>
          <TasksIcon />
          {`${AlectifyText.TASKS} (${sumValues([
            tasks.pendingCounts,
            tasks.completedCounts,
            tasks.skippedCounts,
          ])})`}
        </>
      ),
      children: (
        <Tasks scroll={{ x: "max-content", y: "calc(100vh - 350px)" }} />
      ),
    },
    /*  ...(!isExternalUser(user)
      ? [
          {
            key: "pm-internal",
            label: (
              <>
                <PmInternalIcon />{" "}
                {`${AlectifyText.PM_INTERNAL} (${sumValues([
                  pmInternal.pendingCounts,
                  pmInternal.completedCounts,
                  pmInternal.skippedCounts,
                ])})`}
              </>
            ),
            children: <PmInternal />,
          },
        ]
      : []), */
  ];

  /*  const onButtonChange = ({ target: { value } }: RadioChangeEvent) => {
    dispatch(setShowMyWorkOrders(value));
  }; */

  // useEffect(() => {
  //   dispatch(
  //     getPmsCounts(
  //       "all",
  //       "all",
  //       common.showMyWorkOrders
  //         ? {
  //             assignees: [user.id],
  //             createdById: user.id,
  //           }
  //         : {},
  //     ),
  //   );
  // }, [common.showMyWorkOrders]);

  // useEffect(() => {
  //   if (isExternalUser(user)) {
  //     dispatch(setShowMyWorkOrders(true));
  //   } else {
  //     dispatch(setShowMyWorkOrders(false));
  //   }
  // }, []);

  return (
    <>
      <Breadcrumb
        items={[
          {
            title: "Home",
            href: `#`,
            onClick: () => {
              navigate(
                `${isExternalUser(user) ? ROUTES.MY_ITEMS : ROUTES.PROJECT}`,
              );
            },
          },
          {
            title: common.showMyWorkOrders
              ? AlectifyText.WORK_ORDERS
              : AlectifyText.ALL_WORK_ORDERS,
          },
        ]}
      />
      <div className="sub-project-tabs">
        <Col span={24}>
          <Row justify={"space-between"} align={"middle"}>
            <Col span={12} className="d-flex align-items-center">
              <Typography.Title level={4} className="mt-10">
                {common.showMyWorkOrders
                  ? AlectifyText.MY_WORK_ORDERS
                  : AlectifyText.ALL_WORK_ORDERS}
              </Typography.Title>
              <ProcedureViewSwitcher
                onChange={handleSwitchView}
                tableView={pmExternal.activeWorkOrderCardSwitcher}
              />
            </Col>
            {/* {!isExternalUser(user) && (
              <Col span={12} style={{ textAlign: "right" }}>
                <Space className="alectify-work-order-tabs">
                  <Radio.Group
                    options={
                      isExternalUser(user)
                        ? [MY_WORK_ORDER_OPTIONS[0]]
                        : MY_WORK_ORDER_OPTIONS
                    }
                    onChange={onButtonChange}
                    value={common.showMyWorkOrders}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Space>
              </Col>
            )} */}
          </Row>
        </Col>

        <Tabs
          defaultActiveKey={activeTab}
          items={items}
          onChange={(tabId) => navigate({ search: `page=${tabId}` })}
        />
      </div>
    </>
  );
};

export default MyWorkOrders;
