import { memo } from "react";
interface ICheckMarkSingleIconProps {
  fill?: string | null;
}
const CheckMarkSingleIcon: React.FC<ICheckMarkSingleIconProps> = (
  props: ICheckMarkSingleIconProps,
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="10"
      viewBox="0 0 14 12"
      fill="none"
    >
      <path
        d="M1.5 7.26697L4.32202 10.089L12.5 1.91101"
        stroke={props.fill || "#0BB731"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default memo(CheckMarkSingleIcon);
