import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Breadcrumb } from "antd";
import { ROUTES } from "routes/Routes.constants";
import { ICalendarEventState } from "./Calendar.interface";
import AlectifyText from "static/texts.json";
import WeeklyCalendar from "./WeeklyCalendar";
import CalendarFilters from "./CalendarFilters";
import dayjs from "dayjs";
import { fetchPmCalendarEvents } from "services/calendar/calendar.service";
import {
  ICalendarDatesState,
  ICalendarState,
} from "./MonthlyCalendar.interface";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import { IPmCalendarEventResponse } from "services/calendar/calendar.interface";
import { SERVER_DATE_FORMAT } from "constants/index";
import { formatDate } from "utils/helpers";
import FullMonthViewCalendar from "./FullMonthViewCalendar";
import MiniMonthlyCalendar from "./MiniMonthlyCalendar";
import "./Calendar.scss";

const Calendar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const startDateQ = new URLSearchParams(location.search).get("start") || "";

  const { showCalendarMyWorkOrders, activeMasterProject } = useSelector(
    (state: IRootState) => state.common,
  );

  const [calendarSwitch, setCalendarSwitch] = useState<number>(1);

  const [calendarState, setCalendarState] = useState<ICalendarState>({
    isSelectActive: false,
    selectedDate: new Date(),
  });

  const [miniMonthlyCalendarDate, setMiniMonthlyCalendarDate] = useState(
    dayjs(),
  );

  const miniMonthlyCalendarMonthAndYear = useRef<ICalendarDatesState>({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });

  const [calendarEvents, setCalendarEvents] = useState<ICalendarEventState>({
    data: [],
    fetching: false,
  });

  const getMiniMonthlyEvents = async () => {
    try {
      setCalendarEvents({ fetching: true });
      let pmEvents: IPmCalendarEventResponse;

      const { year, month } = miniMonthlyCalendarMonthAndYear.current;

      const startDate = formatDate(
        dayjs(`${year}-${month + 1}-01`)
          .startOf("month")
          .startOf("week"),
        SERVER_DATE_FORMAT,
      );

      const endDate = formatDate(
        dayjs(`${year}-${month + 1}-01`)
          .endOf("month")
          .endOf("week"),
        SERVER_DATE_FORMAT,
      );

      if (showCalendarMyWorkOrders || showCalendarMyWorkOrders === undefined) {
        const params = {
          isRecordExist: true,
        };

        [pmEvents] = await Promise.all([
          fetchPmCalendarEvents(
            activeMasterProject?.id ?? "all",
            "all",
            startDate,
            endDate,
            params,
          ),
        ]);

        setCalendarEvents({
          fetching: false,
          data: [...pmEvents.data],
        });
      } else {
        const params = {
          isRecordExist: true,
          global: "true",
        };

        pmEvents = await fetchPmCalendarEvents(
          activeMasterProject?.id ?? "all",
          "all",
          startDate,
          endDate,
          params,
        );

        setCalendarEvents({
          fetching: false,
          data: [...pmEvents.data],
        });
      }
    } catch (error) {
      setCalendarEvents({ fetching: false });
    }
  };

  useEffect(() => {
    getMiniMonthlyEvents();
  }, [
    startDateQ,
    miniMonthlyCalendarMonthAndYear.current.month,
    miniMonthlyCalendarMonthAndYear.current.year,
    activeMasterProject?.id,
    calendarSwitch,
  ]);

  useEffect(() => {
    const { year, month } = miniMonthlyCalendarMonthAndYear.current;

    const currentDate = dayjs().year(year).month(month);
    const startDate = currentDate.startOf("week").format("YYYY-MM-DD");
    const endDate = currentDate.endOf("week").format("YYYY-MM-DD");

    const searchParams = new URLSearchParams(location.search);
    searchParams.set("start", startDate);
    searchParams.set("end", endDate);
    searchParams.set("calendarType", calendarSwitch === 1 ? "week" : "month");

    navigate(`?${searchParams.toString()}`, { replace: true });
  }, []);

  // Will be enabled in future
  // useEffect(() => {
  //   if (location.pathname.includes("/company-wide/calendar")) {
  //     dispatch(setActiveMasterProject(null));
  //   }
  // }, []);

  return (
    <div className="calendar-container">
      <div className="d-flex mt-5 mb-5">
        <div className="breadcrumb-container">
          <Breadcrumb
            items={[
              {
                title: "Home",
                href: `#`,
                onClick: () => navigate(`${ROUTES.PROJECT}`),
              },
              {
                title: `${AlectifyText.CALENDAR}`,
              },
            ]}
          />
        </div>
      </div>

      <div className="calendar-content">
        <div className="monthly-calendar-col">
          <div className="monthly-main">
            <MiniMonthlyCalendar
              miniMonthlyCalendarDate={miniMonthlyCalendarDate}
              setMiniMonthlyCalendarDate={setMiniMonthlyCalendarDate}
              calendarEvents={calendarEvents}
              miniMonthlyCalendarMonthAndYear={miniMonthlyCalendarMonthAndYear}
            />
          </div>
          <div className="site-title">{AlectifyText.SITES}</div>
          <div className="calendar-filters-container">
            <CalendarFilters />
          </div>
        </div>

        <div className="weekly-calendar-col">
          {calendarSwitch === 1 ? (
            <WeeklyCalendar
              calendarSwitch={calendarSwitch}
              setCalendarSwitch={setCalendarSwitch}
              setCalendarState={setCalendarState}
              miniMonthlyCalendarMonthAndYear={miniMonthlyCalendarMonthAndYear}
              selectedDate={calendarState?.selectedDate}
            />
          ) : (
            <FullMonthViewCalendar
              miniMonthlyCalendarMonthAndYear={miniMonthlyCalendarMonthAndYear}
              calendarSwitch={calendarSwitch}
              setCalendarSwitch={setCalendarSwitch}
              setCalendarState={setCalendarState}
              selectedDate={calendarState?.selectedDate}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default Calendar;
