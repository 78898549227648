import React, { useEffect, useState } from "react";
import { Col, Divider, Form, Row, Spin, TimePicker, Typography } from "antd";
import {
  IDrProjectBasicInfoProps,
  IDrProjectCreationState,
} from "./DrProjectCreation.interface";
import AlectifyInput from "components/shared/input";
import AlectifyText from "static/texts.json";
import AlectifySelect from "components/shared/select";
import { MESSAGES } from "constants/messages";
import dayjs from "dayjs";
import AlectifyTable from "components/shared/table";
import { isEmpty } from "lodash";
import { IShiftSchedule } from "services/data-round/data-round.interface";
import "./DrCreateEdit.scss";
import { SHIFTS_PER_DAY } from "services/data-round/data-round.constants";
import { getDRProjectDetails } from "services/data-round/data-round.service";

const DrProjectBasicInfo: React.FC<IDrProjectBasicInfoProps> = (
  props: IDrProjectBasicInfoProps,
) => {
  const [shifts, setShifts] = useState<IShiftSchedule[]>([]);
  const [shiftCount, setShiftCount] = useState<number | null>(1); // Default to null
  const [startTime, setStartTime] = useState<string | null>(null); // Default start time (HH:mm)
  const [state, setState] = useState<IDrProjectCreationState>({
    projectId: "",
    selectedValues: [1],
    currentActiveTab: 1,
    loading: false,
    project: null,
  });

  useEffect(() => {
    // Calculate shift times when shiftCount or startTime changes
    const calculateShifts = () => {
      if (shiftCount && startTime) {
        const shiftTimes: IShiftSchedule[] = [];
        let currentStartTime = dayjs(startTime, "HH:mm"); // Use the exact start time without rounding
        const hoursInShift = 24 / shiftCount; // Calculate hours per shift

        for (let i = 0; i < shiftCount; i++) {
          const startTimeFormatted = currentStartTime.format("HH:mm");
          const endTime = currentStartTime
            .add(hoursInShift, "hour")
            .subtract(1, "minute")
            .format("HH:mm");
          shiftTimes.push({
            name: `Shift ${i + 1}`,
            start_time: startTimeFormatted,
            end_time: endTime,
            frequency_type: 1,
          });

          // Move the start time for the next shift one minute after the current end time
          currentStartTime = dayjs(endTime, "HH:mm").add(1, "minute");
        }
        props.shiftScheduleRef.current = shiftTimes;
        setShifts(shiftTimes);
      } else {
        setShifts([]);
      }
    };

    calculateShifts();
  }, [shiftCount, startTime]);

  const handleTimeChange = (time: any, timeString: string) => {
    // Ensure timeString is in HH:mm format and round to nearest hour
    if (timeString) {
      const formattedTime = dayjs(timeString, "HH:mm").format("HH:mm");
      props.startTimeRef.current = formattedTime;
      props.FormInstance.setFieldValue(
        "start_time_first_shift",
        dayjs(timeString, "HH:mm"),
      );
      setStartTime(formattedTime);
    } else {
      setStartTime(null);
    }
  };

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "key",
      key: "key",
      render: (text: any, record: any, index: number) => index + 1,
    },
    {
      title: "Shift Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
      key: "start_time",
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      key: "end_time",
    },
  ];

  const getDetails = async () => {
    try {
      setState((prev: IDrProjectCreationState) => {
        return {
          ...prev,
          loading: true,
        };
      });
      const response = await getDRProjectDetails(props.projectId || "");
      if (response.success) {
        setState((prev: IDrProjectCreationState) => {
          return {
            ...prev,
            project: response.data,
            loading: false,
          };
        });
        props.FormInstance.setFields([
          {
            name: "name",
            value: response.data?.name,
          },
          {
            name: "shifts_per_day",
            value: Number(response.data?.shifts_per_day),
          },
          {
            name: "start_time_first_shift",
            value: dayjs(response.data?.start_time_first_shift, "HH:MM"),
          },
        ]);
        setShifts(response.data?.shift_schedules);
        setShiftCount(response.data?.shifts_per_day || null);
        handleTimeChange("", response.data?.start_time_first_shift);
      }
    } catch (ex) {
      setState((prev: IDrProjectCreationState) => {
        return {
          ...prev,
          loading: false,
        };
      });
      console.log(ex);
    }
  };
  useEffect(() => {
    if (!isEmpty(props.project)) {
      setShifts(props.project?.shift_schedules);
      setStartTime(props.project?.start_time_first_shift);
      setShiftCount(props.project?.shifts_per_day || 0);
    }
    if (props.isEditing) {
      getDetails();
    } else {
      props.FormInstance.setFieldValue("shifts_per_day", 1);
    }
  }, []);

  const calculateShiftsPerHour = (shiftCount: number) => {
    if (!shiftCount) return 0;

    const shiftsPerHour = 24 / shiftCount;

    // Check if the number has a decimal part
    if (shiftsPerHour % 1 !== 0) {
      return shiftsPerHour.toFixed(1);
    } else {
      return shiftsPerHour;
    }
  };

  return (
    <Spin spinning={state.loading}>
      <Form
        name="project-info-form"
        layout="vertical"
        form={props.FormInstance}
        className="dr-project-creation-form-basic-info-container"
        // onFinish={onSubmitProfile}
      >
        <Row justify={"start"} align={"middle"} gutter={20}>
          <Col span={24}>
            <AlectifyInput
              name="name"
              placeholder="Project Name"
              type="text"
              label={AlectifyText.PROJECT_NAME}
              defaultValue={state.project?.name}
              rules={[
                {
                  required: true,
                  message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                    "{fieldName}",
                    AlectifyText.PROJECT_NAME,
                  ),
                },
              ]}
            />
          </Col>
          <Col span={8}>
            <AlectifySelect
              label="Frequency"
              options={[
                {
                  key: "daily",
                  label: "Daily",
                  value: 1,
                },
              ]}
              name="shiftFrequency"
              defaultValue={1}
              disabled
            />
          </Col>
          <Col span={8}>
            <AlectifySelect
              label="Number of shifts per day"
              options={SHIFTS_PER_DAY}
              name="shifts_per_day"
              onChange={(value) => setShiftCount(Number(value))}
              rules={[
                {
                  required: true,
                  message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                    "{fieldName}",
                    AlectifyText.SHIFTS_PER_DAY,
                  ),
                },
              ]}
              defaultValue={1}
            />
          </Col>
          <Col span={8}>
            <Form.Item
              label={AlectifyText.START_TIME_OF_FIRST_SHIFT}
              name="start_time_first_shift"
              rules={[
                {
                  required: true,
                  message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                    "{fieldName}",
                    AlectifyText.START_TIME_OF_FIRST_SHIFT,
                  ),
                },
              ]}
            >
              <TimePicker
                format={"HH:mm"}
                defaultValue={
                  (state.project?.start_time_first_shift &&
                    dayjs(state.project?.start_time_first_shift, "HH:mm")) ||
                  null
                }
                className="project-form-time-picker"
                size="large"
                placeholder="HH:MM"
                onChange={handleTimeChange}
                onSelect={(value: any) => handleTimeChange(null, value)}
                minuteStep={15} // You can keep this for UI consistency
                showNow={false}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider className="mt-10 mb-10" />
        <Row justify={"start"} align={"middle"} className="mb-5">
          <Col span={24}>
            <Typography.Title level={4} className="mt-5">
              Shift Details
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Typography.Paragraph className="m-0">
              Number of hours per shift is{" "}
              <strong>{calculateShiftsPerHour(shiftCount || 0)}</strong> hrs
            </Typography.Paragraph>
          </Col>
        </Row>
        <AlectifyTable
          // tableTitle="Shift Details"
          columns={columns}
          dataSource={!isEmpty(shifts) && !isEmpty(startTime) ? shifts : []}
          total={0}
          onDataFetch={() => {}}
          showPagination={false}
          scroll={{ y: "calc(100vh - 700px)" }}
          // pagination={false}
          // rowKey="name"
          // style={{ marginTop: 20 }}
        />
      </Form>
    </Spin>
  );
};

export default DrProjectBasicInfo;
